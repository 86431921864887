import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const Paragraph = makeShortcode("Paragraph");
const Sectors = makeShortcode("Sectors");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo light" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading className="intro_from_below" mdxType="Heading">
      Sectors
    </Heading>
  </Block>
  <Block className="content_block intro_from_below" mdxType="Block">
    <Paragraph mdxType="Paragraph">Our turnkey solutions and services cover properties, facilities, and assets of all types and sizes, from real estate and shopping complexes to warehouses and industrial camp accommodations.</Paragraph>
  </Block> 
  <Block id="sectors_carousel" mdxType="Block">
    <Sectors mdxType="Sectors" />
  </Block>   
    </AnimateIntroBanner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      